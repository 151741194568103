import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { handleFormValidation } from "../../validation/validation";

const EmailForm = ({ handleEmail, isloading, inputs, setInputs }) => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  const handleClose = () => {
    setErrorMsg("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleCheckValidation = async (e) => {
    let errorMsg = await handleFormValidation(e);
    if (errorMsg) {
      setErrorMsg(errorMsg);
    } else {
      setErrorMsg("");
    }
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center h_100 m-2 m-md-0">
        <div className="col-12 col-lg-6 pe-lg-5 order-1 order-lg-0 mt-5 mt-lg-0">
          <form onSubmit={handleEmail}>
            <div className="forgot_card">
              <h2>Forgot Password?</h2>
              <p className="m-0">
                I remember my password?{" "}
                <a className="pointer" onClick={() => navigate("/login")}>
                  Login
                </a>
              </p>
              <div className="col-12 mt-4">
                {errorMsg && (
                  <div
                    className="alert alert_danger fade show d-flex justify-content-between align-items-center "
                    role="alert"
                  >
                    {errorMsg}
                    <div>
                      <i
                        className="fa-solid fa-xmark"
                        aria-hidden="true"
                        onClick={handleClose}
                      />
                    </div>
                  </div>
                )}
                <TextField
                  id="outlined-basic"
                  label="Email Address"
                  name="email"
                  variant="outlined"
                  fullWidth
                  required
                  value={inputs.email}
                  onChange={handleChange}
                  onBlur={(e) => handleCheckValidation(e)}
                  placeholder="Email Address"
                />
              </div>
              <div className="col-12 mt-4">
                <LoadingButton
                  fullWidth
                  sx={{
                    color: "#ffffff",
                    border: "none",
                    padding: "8px 40px",
                    borderRadius: "12px",
                    background: "#13c57c",
                    fontSize: "16px",
                    boxShadow: 0,
                    textTransform: "none",
                  }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isloading}
                >
                  Submit
                </LoadingButton>
              </div>
            </div>
          </form>
        </div>
        <div className="col-12 col-lg-6 ps-lg-5 mt-3 mt-lg-0 margin-right">
          <h2 className="text-white fw_600">
            Ready to track <br />
            your habits?
          </h2>
          <p className="text-white mt-3">
            Track your habits diligently, for in the rhythm of <br />
            consistency lies the symphony of success.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailForm;
