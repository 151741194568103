import React, { useState } from "react";
import PinCodeForm from "./PinCodeForm";
import ConfrimPassword from "./ConfirmPassword";
import EmailForm from "./EmailForm";
import { emailVerify_Api } from "../../DAL/Login/Login";
import { useSnackbar } from "notistack";

const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [formState, setFormState] = useState(0);
  const [isloading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({ email: "" });

  const handleEmail = async (e) => {
    setLoading(true);
    e.preventDefault();
    const postData = {
      email: inputs.email,
    };
    const result = await emailVerify_Api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setFormState(1);
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <>
      {formState === 0 ? (
        <EmailForm
          setFormState={setFormState}
          handleEmail={handleEmail}
          isloading={isloading}
          inputs={inputs}
          setInputs={setInputs}
        />
      ) : formState === 1 ? (
        <PinCodeForm
          setFormState={setFormState}
          isloading={isloading}
          userEmail={inputs}
        />
      ) : (
        <ConfrimPassword
          setFormState={setFormState}
          userEmail={inputs}
          isloading={isloading}
        />
      )}
    </>
  );
};

export default ForgotPassword;
