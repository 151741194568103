import React, { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { signUp_Api } from "../../DAL/Login/Login";
import { LoadingButton } from "@mui/lab";
import { CustomPhoneInput } from "../../components/CustomPhoneInput";
import { isValidNumber } from "libphonenumber-js";

const Signup = () => {
  const navigate = useNavigate();
  const [isloading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("+44");
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleSubmitSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    const postData = {
      email: inputs.email,
      password: inputs.password,
    };
    const result = await signUp_Api(postData);
    if (result.code === 200) {
      localStorage.setItem("token", result.customer.token);
      localStorage.setItem("user", JSON.stringify(result.customer));
      navigate("/habits", { replace: true });
      enqueueSnackbar(result.message, { variant: "success" });
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center h_100 m-2 m-md-0">
        <div className="col-12 col-lg-6 pe-lg-5 order-1 order-lg-0 mt-5 mt-lg-0">
          <form onSubmit={handleSubmitSignup}>
            <div className="row sigup_card mb-5 mb-md-0">
              <h2>
                Sign up for a 30-day <br /> Free Trial
              </h2>
              <div className="col-12 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Email Address"
                  name="email"
                  variant="outlined"
                  fullWidth
                  required
                  value={inputs.email}
                  onChange={handleChange}
                  placeholder="Email Address"
                />
              </div>
              <div className="col-12 mt-3">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    fullWidth
                    required
                    name="password"
                    value={inputs.password}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          ) : (
                            <Visibility
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </div>
              <div className="col-12 mt-3 check_box ">
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="By clicking Create account, I agree that I have read and accepted the Terms of Use and Privacy Policy."
                  required
                />
              </div>
              <div className="col-12 mt-3">
                <LoadingButton
                  fullWidth
                  sx={{
                    color: "#ffffff",
                    border: "none",
                    padding: "8px 40px",
                    borderRadius: "12px",
                    background: "#13c57c",
                    fontSize: "16px",
                    boxShadow: 0,
                    textTransform: "none",
                  }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isloading}
                >
                  Sign Up
                </LoadingButton>
              </div>
              <p className="m-0 mt-3 text-end">
                Already have an account?{" "}
                <a className="pointer" onClick={() => navigate("/login")}>
                  Sign In
                </a>
              </p>
            </div>
          </form>
        </div>
        <div className="col-12 col-lg-6 ps-lg-5 mt-3 mt-lg-0 margin-right-signup">
          <h2 className="text-white fw_600">
            Sign up for a 30- <br />
            day Free Trial
          </h2>
          <p className="text-white mt-3">
            - &nbsp;The free plan imposes a restriction of only one <br /> habit
            per user.
          </p>
          <p className="text-white mt-3">
            - &nbsp;Upgrade to the pro plan to unleash unlimited <br />
            habits and maximize your progress.
          </p>
          <p className="text-white mt-3">
            - &nbsp;Transform your habits without limits. Upgrade to
            <br /> the pro plan and embrace boundless growth.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
