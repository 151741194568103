import React, { createRef } from "react";
import ThemeConfig from "./theme";
import Routers from "./routes";
import { Slide } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { Helmet } from "react-helmet-async";

import "./assets/css/style.css";
import "react-phone-input-2/lib/style.css";
import "react-calendar-heatmap/dist/styles.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-calendar/dist/Calendar.css";
import favicon from "./assets/images/favicon.png";
import { ContextAdmin } from "./context/Context";

function App() {
  const notistackRef = createRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <ContextAdmin>
        <ThemeConfig>
          <SnackbarProvider
            ref={notistackRef}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            TransitionComponent={Slide}
            maxSnack={3}
            action={(key) => (
              <div onClick={onClickDismiss(key)} className="pointer">
                <i className="fa-solid fa-xmark me-3"></i>
              </div>
            )}
          >
            <Helmet>
              <title>Habit Tracker</title>
              <meta name="description" content="Habit Tracker" />
              <link rel="icon" href={favicon} />
            </Helmet>
            <Routers />
          </SnackbarProvider>
        </ThemeConfig>
      </ContextAdmin>
    </>
  );
}

export default App;
