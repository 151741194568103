import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import ActiveLastBreadcrumb from "../../components/BreadCrums";
import Vector from "../../assets/images/Vector.png";
import { CircularProgress } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import {
  confirmIncompleteSub_api,
  changeOneTimePayment_Api,
  paymentByCust_Api,
  recurringPayment_Api,
} from "../../DAL/Stripe/Stripe";
import { listHabitPlan_Api } from "../../DAL/Plan/Plan";
import { useAdminContext } from "../../context/Context";

const CheckoutForm = () => {
  const params = useParams();
  const stripe = useStripe();
  const location = useLocation();
  const navigate = useNavigate();
  const elements = useElements();
  const { handleId, setPlanInfo } = useAdminContext();
  const { enqueueSnackbar } = useSnackbar();
  const billingIndex = location.state?.cardIndex;
  const [listPlan, setListPlan] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedCard, setSelectedCard] = useState(billingIndex);

  const selectCard = (id) => {
    const selectedIndex = listPlan.findIndex((plan) => plan._id === id);
    if (selectedIndex !== -1) {
      setSelectedCard(selectedIndex);
      navigate(`/billings/check-out/${id}`);
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleListPlan = async () => {
    setLoading(true);
    const result = await listHabitPlan_Api();
    if (result.code === 200) {
      setListPlan(result.plan_list);
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  const handleCard = (data, cardElement) => {
    stripe
      .confirmCardPayment(data.client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(function (result) {
        if (result.error) {
          setIsLoading(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }
        confirmPayment(data.subscription_id);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const recurringPayment = async (cardElement, token) => {
    setIsLoading(true);
    const postData = {
      plan_id: params?.id,
      source_token: token,
    };
    const result = await recurringPayment_Api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleId(result.plan_detail._id);
      setPlanInfo(result.plan_detail);
      localStorage.setItem("current_plan", JSON.stringify(result.plan_detail));
      navigate("/billings/check-out/payment-sucessfull");
      setIsLoading(false);
    } else if (result.code === 210) {
      handleCard(result?.data, cardElement);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const confirmPayment = async (subscription_id) => {
    let postData = {
      subscription_id: subscription_id,
    };
    const result = await confirmIncompleteSub_api(postData);
    if (result.code === 200) {
      handleId(result.data.subscription.plan_info._id);
      setPlanInfo(result.data.subscription.plan_info);
      localStorage.setItem(
        "current_plan",
        JSON.stringify(result.data.subscription.plan_info)
      );
      enqueueSnackbar(result.message, {
        variant: "success",
      });
      navigate("/billings/check-out/payment-sucessfull");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSecureCard = (client_secret, cardElement) => {
    enqueueSnackbar("Processing card...", { variant: "info" });
    stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(function (result) {
        if (result.error) {
          setIsLoading(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }
        paymentConfirmed();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const paymentCustomer = async (cardElement) => {
    setIsLoading(true);
    const postData = {
      plan_id: params?.id,
    };
    const result = await paymentByCust_Api(postData);
    if (result.code === 200) {
      handleSecureCard(result.client_secret, cardElement);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const paymentConfirmed = async () => {
    const postData = {
      plan_id: params?.id,
    };
    const result = await changeOneTimePayment_Api(postData);
    if (result.code === 200) {
      handleId(result.transaction_add.plan_info.plan_id);
      setPlanInfo(result.transaction_add.plan_info);
      localStorage.setItem(
        "current_plan",
        JSON.stringify(result.transaction_add.plan_info)
      );
      navigate("/billings/check-out/payment-sucessfull");
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    let cardElement = null;
    setIsLoading(true);
    cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement).then(function (result) {
      if (result.error) {
        enqueueSnackbar(result.error.message, { variant: "error" });
        setIsLoading(false);
        return;
      }
      let selectedPlan = listPlan[selectedCard];
      if (selectedPlan.type === "recurring") {
        recurringPayment(cardElement, result.token.id);
      } else {
        paymentCustomer(cardElement);
      }
    });
  };

  let breadCrumbMenu = [
    {
      title: "Billings",
      navigation: "/billings",
      active: false,
    },
    {
      title: "Checkout",
      active: true,
    },
  ];

  useEffect(() => {
    handleListPlan();
  }, []);

  useEffect(() => {
    if (params.id && listPlan.length > 0) {
      const selectedIndex = listPlan.findIndex(
        (plan) => plan._id === params.id
      );
      if (selectedIndex !== -1) {
        setSelectedCard(selectedIndex);
      }
    }
  }, [params.id, listPlan]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center alignCenter">
        <CircularProgress color="success" />
      </div>
    );
  }

  return (
    <>
      <div className="payment_card">
        <div className="row">
          <div className="col-12 col-md-6 order-1 order-md-0 ">
            <h4 className="fw_600 mb-0">Checkout</h4>
            <p>Fill in your purchase details</p>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-end order-0 order-md-1">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>

          <div className="col-12 mt-2 order-3 ">
            <div className=" mb-lg-0">
              <span className="me-2 info_text">Plan info</span>
            </div>
            <div className="row">
              {listPlan.map((item, index) => {
                if (!item.is_plan_free) {
                  return (
                    <div
                      className="col-12 col-md-6 mt-3 text-center"
                      key={index}
                    >
                      <div
                        className={`payment_info_card ${
                          index === selectedCard ? "selected" : ""
                        }`}
                        onClick={() => selectCard(item._id)}
                      >
                        <h5 className="fw_600">{item.name}</h5>
                        <h6 className="card-price text-center">
                          ${item.total_amount}
                          <span className="period">
                            {item.type === "recurring"
                              ? "/Yearly Plan"
                              : "/One Time Purchase"}
                          </span>
                        </h6>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            <div className="payment_info d-flex flex-wrap justify-content-between align-items-center mt-3">
              <div className="mb-3 mb-lg-0 ">
                <span className="me-2 info_text">Payment info</span>
                <img src={Vector} />
              </div>
              {errorMessage && (
                <div
                  className="alert alert_danger fade show d-flex justify-content-between align-items-center "
                  role="alert"
                >
                  {errorMessage}
                  <div onClick={handleAlertClose}>
                    <i className="fa-solid fa-xmark ms-2 ms-lg-5"></i>
                  </div>
                </div>
              )}
            </div>

            <form onSubmit={handleSubmit}>
              <div className="payment_info_card mt-3">
                <div className="row justify-content-end">
                  {/* <div className="col-12 col-md-6 mt-2">
                    <TextField
                      id="outlined-basic"
                      label="Name On Card"
                      name="email"
                      variant="outlined"
                      fullWidth
                      required
                      placeholder="Name On Card"
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      required
                      type="email"
                      placeholder="Email"
                    />
                  </div> */}
                  <div className="col-12 mt-2 mt-md-3">
                    <CardElement
                      options={{
                        hidePostalCode: true,
                        style: {
                          base: {
                            iconColor: "#13c57c",
                            color: "#000",
                            "::placeholder": {
                              color: "#000000a6",
                            },
                            fontSize: "14px",
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="col-md-5 text-end mt-3">
                    <div className="d-flex justify-content-between align-items-center ">
                      <p className="fw_600 mb-0">YOUR PLAN</p>
                      <p className="fw_600 mb-0">TOTAL AMOUNT</p>
                    </div>
                    <div className="border border-1 mb-2 mt-2 "></div>
                    <div className="d-flex justify-content-between align-items-center ">
                      <p>{listPlan[selectedCard]?.name}</p>
                      <p>${listPlan[selectedCard]?.total_amount}</p>
                    </div>
                  </div>
                  <div className="col-12 text-end">
                    <LoadingButton
                      sx={{
                        background: "#13c57c",
                        border: "none",
                        color: "#fff",
                        padding: "6px 20px",
                        borderRadius: "6px",
                        fontSize: "14px",
                        boxShadow: 0,
                        textTransform: "none",
                        marginBottom: "15px",
                      }}
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isLoading}
                    >
                      Pay Now
                    </LoadingButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutForm;
