import React, { useEffect, useState } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Avatar from "../../assets/images/user.png";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { userProfileUpdate_Api } from "../../DAL/Login/Login";
import { useSnackbar } from "notistack";
import { baseUrl } from "../../config/config";
import { useAdminContext } from "../../context/Context";
import { TimeZones } from "../../utils/constant";

const Input = styled("input")({
  display: "none",
});

const Settings = () => {
  const { setUserInfo, planInfo } = useAdminContext();
  const { enqueueSnackbar } = useSnackbar();
  const [isloading, setLoading] = useState(false);
  const [previews, setPreviews] = useState("");
  const [image, setImage] = useState("");
  const [timeZoneValue, setTimeZoneValue] = useState("Europe/Dublin");
  const [inputValue, setInputValue] = useState("");
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    short_bio: "",
    old_img: "",
    current_plan: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const previews = URL.createObjectURL(event.target.files[0]);
    setPreviews(previews);
  };

  const handleUserProfileUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const postData = new FormData();
    postData.append("first_name", inputs.first_name);
    postData.append("last_name", inputs.last_name);
    postData.append("email", inputs.email);
    postData.append("short_bio", inputs.short_bio ?? "");
    postData.append("timezone", timeZoneValue);
    if (image) {
      postData.append("image", image);
    }
    const result = await userProfileUpdate_Api(postData);
    if (result.code === 200) {
      setUserInfo(result.data);
      localStorage.setItem("user", JSON.stringify(result.data));
      enqueueSnackbar(result.message, { variant: "success" });
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setInputs((previousData) => ({
        ...previousData,
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        email: userData?.user_id.email,
        short_bio: userData?.short_bio == "undefined" ? "" : userData.short_bio,
        old_img: userData?.profile_image,
        current_plan: planInfo?.type,
      }));
      setTimeZoneValue(userData.timezone);
    }
  }, [localStorage.getItem("user")]);

  return (
    <div className="setting_card">
      <div className="container">
        <form onSubmit={handleUserProfileUpdate}>
          <div className="row">
            <div className="col-12 text-center text-md-start">
              <h2>Update Profile</h2>
            </div>
            <div className="col-12 mb-2 text-center mx-auto">
              <img
                className="ml-sm-5"
                src={
                  previews
                    ? previews
                    : inputs.old_img
                    ? baseUrl + inputs.old_img
                    : Avatar
                }
                alt=""
              />
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  name="profile_image"
                  onChange={(e) => handleUpload(e)}
                />
                <IconButton
                  className="edit_profile_icon"
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <PhotoCamera />
                </IconButton>
              </label>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <TextField
                id="outlined-basic"
                label="Your First Name"
                name="first_name"
                value={inputs.first_name}
                onChange={handleChange}
                required
                variant="outlined"
                autoComplete="off"
                fullWidth
                placeholder="Your First Name"
              />
            </div>
            <div className="col-12 col-md-6 mt-3">
              <TextField
                id="outlined-basic"
                label="Your Last Name"
                name="last_name"
                required
                value={inputs.last_name}
                onChange={handleChange}
                autoComplete="off"
                variant="outlined"
                fullWidth
                placeholder="Your Last Name"
              />
            </div>
            <div className="col-12 col-md-6 mt-3">
              <TextField
                id="outlined-basic"
                label="Your Email"
                required
                autoComplete="off"
                name="email"
                value={inputs.email}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                placeholder="Your Email"
              />
            </div>
            <div className="col-12 col-md-6 mt-3">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Subscription
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Your Level"
                  name="status"
                  autoComplete="off"
                  value={inputs.current_plan ?? "Basic"}
                  disabled
                >
                  <MenuItem value={inputs.current_plan ?? "Basic"}>
                    {inputs.current_plan === "onetime"
                      ? "Lifetime"
                      : inputs.current_plan === "recurring"
                      ? "Yearly"
                      : "Free"}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <Autocomplete
                value={timeZoneValue}
                onChange={(event, newValue) => {
                  setTimeZoneValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="controllable-states-demo"
                options={TimeZones}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Time Zone" />
                )}
              />
            </div>
            <div className="col-12 mt-2">
              <span className="short-text">Short Bio</span>
              <TextareaAutosize
                aria-label="Short Bio"
                name="short_bio"
                label="Short Bio"
                autoCapitalize="off"
                id="outlined-basic"
                variant="outlined"
                value={inputs.short_bio}
                onChange={handleChange}
                className="textarea-autosize"
                placeholder="Short Bio"
                style={{
                  width: "100%",
                  height: "100px",
                  backgroundColor: "#ffffff",
                  color: "#000",
                  borderRadius: "5px",
                  paddingTop: "5px",
                  paddingLeft: "13px",
                  outline: "none",
                  border: "1px solid #0000003b",
                }}
              />
            </div>
            <div className="col-12 text-end button_p mt-3">
              <LoadingButton
                sx={{
                  color: "#ffffff",
                  border: "none",
                  padding: "8px 40px",
                  borderRadius: "12px",
                  background: "#13c57c",
                  marginBottom: "8px",
                  boxShadow: 0,
                  textTransform: "none",
                }}
                size="large"
                type="submit"
                variant="contained"
                loading={isloading}
              >
                Save
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Settings;
