import React from "react";
import { Toolbar, Drawer, Divider, Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import NavSection from "./NavSection";
import { SideBarConfig } from "./SideBarConfig";

const drawerWidth = 260;

function AppSideBar({ mobileOpen, handleDrawerToggle }) {
  let nav_items = SideBarConfig();

  const drawer = (
    <>
      <div className="text-center p-4">
        <h4 className="fw-bolder">Habit Tracker</h4>
      </div>
      <Divider />
      <NavSection navConfig={nav_items} />
    </>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "white",
          minHeight: "100vh",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </>
  );
}

export default AppSideBar;
