import React from "react";
import { Toolbar, CssBaseline, AppBar, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "../../assets/images/user.png";
import { baseUrl } from "../../config/config";
import { useAdminContext } from "../../context/Context";

const drawerWidth = 260;

function AppHeader({ handleDrawerToggle }) {
  const { userinfo } = useAdminContext();

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#fff",
          boxShadow: "0px 1px 7px rgba(19, 197, 124, 1)",
        }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <div className="d-flex justify-content-end align-items-center w-100">
            <img
              src={
                userinfo?.profile_image
                  ? baseUrl + userinfo?.profile_image
                  : Avatar
              }
              alt=""
              className="rounded-circle header-profile-user me-2"
            />

            <div className="user_name">
              <h5>
                {userinfo?.first_name ? userinfo.first_name : ""}{" "}
                {userinfo?.last_name ? userinfo.last_name : ""}
              </h5>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default AppHeader;
