import React, { useState } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { login } from "../../DAL/Login/Login";
import { LoadingButton } from "@mui/lab";
import { handleFormValidation } from "../../validation/validation";
import { useAdminContext } from "../../context/Context";

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setUserInfo } = useAdminContext();
  const navigate = useNavigate();
  const [isloading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClose = () => {
    setErrorMsg("");
  };

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleCheckValidation = async (e) => {
    let errorMsg = await handleFormValidation(e);
    if (errorMsg) {
      setErrorMsg(errorMsg);
    } else {
      setErrorMsg("");
    }
  };

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const postData = {
      email: inputs.email,
      password: inputs.password,
    };
    const result = await login(postData);
    if (result.code === 200) {
      setUserInfo(result.user);
      localStorage.setItem("token", result.token);
      localStorage.setItem("user", JSON.stringify(result.user));
      navigate("/habits", { replace: true });
      enqueueSnackbar(result.message, { variant: "success" });
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center h_100 m-2 m-md-0">
        <div className="col-12 col-lg-6 pe-lg-5 order-1 order-lg-0 mt-5 mt-lg-0">
          <form onSubmit={handleSubmitLogin}>
            <div className="login_card">
              <h2>Sign In</h2>
              <p>
                New user?{" "}
                <a className="pointer" onClick={() => navigate(`/signup`)}>
                  Create an acount
                </a>
              </p>
              {errorMsg && (
                <div
                  className="alert alert_danger fade show d-flex justify-content-between align-items-center "
                  role="alert"
                >
                  {errorMsg}
                  <div>
                    <i
                      className="fa-solid fa-xmark"
                      aria-hidden="true"
                      onClick={handleClose}
                    />
                  </div>
                </div>
              )}
              <div className="col-12 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Email Address"
                  name="email"
                  variant="outlined"
                  fullWidth
                  required
                  value={inputs.email}
                  onChange={handleChange}
                  onBlur={(e) => handleCheckValidation(e)}
                  placeholder="Email Address"
                />
              </div>
              <div className="col-12 mt-2">
                <div className="position-relative">
                  <div className="float-end pb-2">
                    <a
                      className="text-muted pointer"
                      onClick={() => navigate(`/forgot-password`)}
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password *"
                    fullWidth
                    required
                    name="password"
                    value={inputs.password}
                    onChange={handleChange}
                    onBlur={(e) => handleCheckValidation(e)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          ) : (
                            <Visibility
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </div>
              <div className="col-12 mt-4">
                <div className="d-flex justify-content-end">
                  <LoadingButton
                    sx={{
                      color: "#ffffff",
                      border: "none",
                      padding: "8px 39px",
                      borderRadius: "12px",
                      background: "#13c57c",
                      fontSize: "16px",
                      boxShadow: 0,
                      textTransform: "none",
                    }}
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isloading}
                  >
                    Sign In
                  </LoadingButton>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-12 col-lg-6 ps-lg-5 mt-3 mt-lg-0 order-0 margin-right">
          <h2 className="text-white fw_600 ">
            Ready to track
            <br /> your habits?
          </h2>
          <p className="text-white mt-3">
            Track your habits diligently, for in the rhythm of <br />
            consistency lies the symphony of success.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
