import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

export default function ActiveLastBreadcrumb({ breadCrumbMenu }) {
  const navigate = useNavigate();
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadCrumbMenu.map((item) => {
        return (
          <Link
            underline="hover"
            color={item.active == true ? "" : "#000"}
            onClick={() => navigate(item?.navigation)}
          >
            <span style={{ cursor: "pointer" }}>{item?.title}</span>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
