import React, { useEffect, useState } from "react";
import AppHeader from "./AppHeader";
import AppSideBar from "./AppSideBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { customerInitToken_Api } from "../../DAL/Stripe/Stripe";
import { useAdminContext } from "../../context/Context";
import { CircularProgress } from "@mui/material";

export default function DashboardLayout() {
  const { handleStripe, handleId, isLogOut } = useAdminContext();
  const pathname = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setIsLoading] = useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const customerToken = async () => {
    const result = await customerInitToken_Api();
    if (result.code === 200) {
      handleStripe(result.data);
      handleId(result?.data?.customer?.current_plan?._id);
      if (result?.data?.customer?.current_plan) {
        localStorage.setItem(
          "current_plan",
          JSON.stringify(result?.data?.customer?.current_plan)
        );
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      setIsLoading(false);
    } else {
      customerToken();
    }
  }, []);

  useEffect(() => {
    setMobileOpen(false);
  }, [pathname]);

  if (loading || isLogOut) {
    return (
      <div className="d-flex justify-content-center alignCenter loader_icon">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="d-flex position-relative">
      <AppHeader handleDrawerToggle={handleDrawerToggle} />
      <AppSideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
    </div>
  );
}
