import React, { useEffect, useState } from "react";
import { DialogActions, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { editHabit_Api } from "../../DAL/Habit/Habit";
import moment from "moment";

const EditHabbit = ({ setOpen, userId, setListHabit }) => {
  const [isloading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    name: "",
    // start_date: dayjs(new Date()),
    // end_date: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };
  const handleChangeDate = (value, name) => {
    setInputs({ ...inputs, [name]: value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitHabit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const postData = {
      name: inputs.name,
      // start_date: moment(inputs.start_date?.$d).format("YYYY-MM-DD"),
      // end_date: moment(inputs.end_date?.$d).format("YYYY-MM-DD"),
    };
    const result = await editHabit_Api(postData, userId._id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setListHabit((prevData) => {
        const updatedData = prevData.map((item) => {
          if (item._id === result.habit.habit._id) {
            return result.habit.habit;
          } else {
            return item;
          }
        });
        return updatedData;
      });

      setLoading(false);
      setOpen(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      setInputs({
        name: userId.name,
        start_date: dayjs(userId.start_date),
        end_date: dayjs(userId.end_date),
      });
    }
  }, [userId]);

  return (
    <form onSubmit={handleSubmitHabit}>
      <div className="row">
        <div className="col-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Name"
            name="name"
            value={inputs.name}
            onChange={(e) => handleChange(e)}
            variant="outlined"
            fullWidth
            required
            placeholder="Name *"
          />
        </div>

        {/* <div className="col-12 col-md-6 mt-4">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date *"
              name="start_date"
              value={inputs.start_date}
              onChange={(e) => handleChangeDate(e, "start_date")}
              required
              disabled
              format="DD-MM-YYYY"
              inputFormat="dd-MM-yyyy"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="col-12 col-md-6 mt-4">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date *"
              name="end_date"
              value={inputs.end_date}
              onChange={(e) => handleChangeDate(e, "end_date")}
              required
              format="DD-MM-YYYY"
              inputFormat="dd-MM-yyyy"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div> */}
        <div className="mt-3">
          <DialogActions sx={{ padding: "0px" }}>
            <button
              className="outline_btn_popover"
              onClick={(e) => {
                handleClose();
                e.preventDefault();
              }}
            >
              Cancel
            </button>
            <LoadingButton
              sx={{
                background: "#13c57c",
                border: "none",
                color: "#fff",
                padding: "6px 20px",
                borderRadius: "6px",
                fontSize: "14px !important",
                marginBottom: "8px",
                boxShadow: 0,
                textTransform: "none",
              }}
              size="large"
              type="submit"
              variant="contained"
              loading={isloading}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </div>
      </div>
    </form>
  );
};

export default EditHabbit;
