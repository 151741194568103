import React from "react";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function CustomModal({ open, setOpen, title, page_data }) {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: isMdScreen ? "500px" : "100%",
          },
        }}
      >
        <div className="p-3">
          <h4>{title}</h4>
          <div>{page_data}</div>
        </div>
        {/* <DialogActions>
            <button className="outline_btn_popover" onClick={handleClose}>
              Cancel
            </button>
            <button
              type="submit"
              className="button_popover me-2"
              onClick={handleClose}
            >
              Save
            </button>
          </DialogActions> */}
      </Dialog>
    </>
  );
}
