import React, { useState } from "react";
import PinInput from "react-pin-input";
import { codeVerify_Api } from "../../DAL/Login/Login";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

const PinCodeForm = ({ userEmail, setFormState }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [pinValue, setPinValue] = useState("");
  const [isloading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleClose = () => {
    setErrorMsg("");
  };

  const handleCodeVerification = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!pinValue) {
      setErrorMsg(
        <div
          className="alert alert_danger fade show d-flex justify-content-between align-items-center "
          role="alert"
        >
          Please enter your OTP
          <div>
            <i
              className="fa-solid fa-xmark"
              aria-hidden="true"
              onClick={handleClose}
            />
          </div>
        </div>
      );
      setLoading(false);
      return;
    } else {
      setErrorMsg("");
    }
    const postData = {
      email: userEmail.email,
      verification_code: pinValue,
    };
    const result = await codeVerify_Api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setFormState(2, userEmail);
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center h_100 m-2 m-md-0 ">
        <div className="col-12 col-lg-6 pe-lg-5 order-1 order-lg-0 mt-5 mt-lg-0">
          <form onSubmit={handleCodeVerification}>
            <div className="forgot_card">
              <h2>Please check your email</h2>
              {errorMsg}
              <p className="m-0">Enter PIN Code here.</p>
              <div className="col-12 mt-3">
                <PinInput
                  length={6}
                  secret={false}
                  onChange={(value) => setPinValue(value)}
                  onComplete={(value) => setPinValue(value)}
                  type="numeric"
                  inputMode="number"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  inputStyle={{ borderColor: "red" }}
                  inputFocusStyle={{ borderColor: "#438a7a" }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </div>
              <div className="col-12 mt-4">
                <LoadingButton
                  fullWidth
                  sx={{
                    color: "#ffffff",
                    border: "none",
                    padding: "8px 40px",
                    borderRadius: "12px",
                    background: "#13c57c",
                    fontSize: "16px",
                    boxShadow: 0,
                    textTransform: "none",
                  }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isloading}
                >
                  Submit
                </LoadingButton>
              </div>
            </div>
          </form>
        </div>
        <div className="col-12 col-lg-6 ps-lg-5 mt-3 mt-lg-0 margin-right">
          <h2 className="text-white fw_600">
            Ready to track <br />
            your habits?
          </h2>
          <p className="text-white mt-3">
            Track your habits diligently, for in the rhythm of <br />
            consistency lies the symphony of success.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PinCodeForm;
