import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { pass_ConfirmPass_Api } from "../../DAL/Login/Login";

const ConfirmPassword = ({ userEmail }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isloading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmitNewPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    const postData = {
      email: userEmail.email,
      password: newPassword,
      confirm_password: confirmPassword,
    };

    if (newPassword !== confirmPassword) {
      enqueueSnackbar("Password and Confirm Password Must be same", {
        variant: "error",
      });
      setLoading(false);
      return;
    }
    const result = await pass_ConfirmPass_Api(postData);
    if (result.code === 200) {
      navigate("/login");
      enqueueSnackbar(result.message, { variant: "success" });
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center h_100 m-2 m-md-0">
        <div className="col-12 col-lg-6 pe-lg-5 order-1 order-lg-0 mt-5 mt-lg-0">
          <form onSubmit={handleSubmitNewPassword}>
            <div className="forgot_card">
              <h2>Forgot Password?</h2>
              <p className="m-0">Reset Password</p>
              <div className="col-12 mt-4">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password *"
                    fullWidth
                    required
                    onChange={(e) => setNewPassword(e.target.value)}
                    name="password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          ) : (
                            <Visibility
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </div>
              <div className="col-12 mt-4">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Confirm Password *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm Password *"
                    fullWidth
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    name="confirm_password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          ) : (
                            <Visibility
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                </FormControl>
              </div>
              <div className="col-12 mt-4">
                <LoadingButton
                  fullWidth
                  sx={{
                    color: "#ffffff",
                    border: "none",
                    padding: "8px 40px",
                    borderRadius: "12px",
                    background: "#13c57c",
                    fontSize: "16px",
                    boxShadow: 0,
                    textTransform: "none",
                  }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isloading}
                >
                  Submit
                </LoadingButton>
              </div>
            </div>
          </form>
        </div>
        <div className="col-12 col-lg-6 ps-lg-5 mt-3 mt-lg-0 margin-right">
          <h2 className="text-white fw_600">
            Ready to track <br />
            your habits?
          </h2>
          <p className="text-white mt-3">
            Track your habits diligently, for in the rhythm of <br />
            consistency lies the symphony of success.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPassword;
