import React from "react";
import { Dialog, DialogTitle } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LoadingButton } from "@mui/lab";

function DeleteConfirmation({
  confirm,
  isLoading,
  setConfirm,
  title,
  deleteHabitList,
}) {
  const handleCloseDialog = () => {
    setConfirm(false);
  };
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Dialog
        open={confirm}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
            width: isMdScreen ? "500px" : "100%",
          },
        }}
      >
        <DialogTitle>
          <div className="text-center p-4">
            <div className="mt-3">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                {title ? title : "Are you sure you want to delete this habit."}
              </p>
            </div>
            <div className="d-flex justify-content-center gap-2 mt-4">
              <button
                className="outline_btn_popover_2"
                onClick={handleCloseDialog}
              >
                Close
              </button>
              <LoadingButton
                sx={{
                  background: "#13c57c",
                  border: "none",
                  color: "#fff",
                  padding: "7px 20px",
                  borderRadius: "6px",
                  fontSize: "14px !important",
                  marginBottom: "15px",
                  boxShadow: 0,
                  textTransform: "none",
                }}
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
                onClick={deleteHabitList}
              >
                Delete
              </LoadingButton>
            </div>
          </div>
        </DialogTitle>
      </Dialog>
    </>
  );
}

export default DeleteConfirmation;
