import React, { useCallback, useEffect, useRef, useState } from "react";
import { CircularProgress, ListItemIcon, Menu, MenuItem } from "@mui/material";
import CustomModal from "../../components/CustomModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddHabbit from "./AddHabbit";
import EditHabbit from "./EditHabbit";
import { useSnackbar } from "notistack";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import BuyHabbit from "../../components/BuyHabbit";
import { deleteHabit_Api, listHabit_Api } from "../../DAL/Habit/Habit";
import HabitCard from "./HabitCard";

const Habbit = () => {
  const observer = useRef();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirm1, setConfirm1] = useState(false);
  const [loading, setLoading] = useState(true);
  const [delLoading, setDelLoading] = useState(false);
  const [listHabit, setListHabit] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userId, setUserId] = useState({});
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const load_more_path = `api/habit_tracker/list_habit?page=0&limit=10`;

  const openNew = Boolean(anchorEl);

  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget);
    setUserId(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    if (
      totalCount.allowed_habits == totalCount.count ||
      totalCount.count > totalCount.allowed_habits
    ) {
      setConfirm1(true);
    } else {
      setOpen(true);
    }
  };

  const handleClickOpenEdit = () => {
    setEdit(true);
  };

  const handleDeletePopup = () => {
    setConfirm(true);
  };

  const handleListHabit = async (path, new_list, loading) => {
    if (loading) {
      setLoading(true);
    }
    const result = await listHabit_Api(path);
    if (result.code === 200) {
      setLoading(false);
      let new_array = new_list.concat(result.habits.habit_list);
      setListHabit(new_array);
      setTotalCount(result.habits);
      setLoadMorePath(result.habits.load_more_url);
      setTotalPages(result.habits.total_pages);
      setPageNumber((prev) => prev + 1);
      setIsLoadingMore(false);
    } else {
      setLoading(false);
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const deleteHabitList = async (e) => {
    e.preventDefault();
    setDelLoading(true);
    const habit_id = userId?._id;
    const result = await deleteHabit_Api(habit_id);
    if (result.code === 200) {
      let array = listHabit.filter((item) => item._id !== habit_id);
      setListHabit(array);
      setTotalCount((prev) => ({
        ...prev,
        count: prev.count - 1,
      }));
      setConfirm(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages >= pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, loadMorePath, observer]
  );

  const loadMoreData = () => {
    setIsLoadingMore(true);
    handleListHabit(loadMorePath, listHabit);
  };

  useEffect(() => {
    handleListHabit(load_more_path, [], "true");
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center alignCenter">
        <CircularProgress color="success" />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-end">
          <button
            className="button_page ms-2 mt-2 mt-lg-0"
            onClick={handleClickOpen}
          >
            <i className="fa-solid fa-plus pe-2"></i> Add Habit
          </button>
        </div>
        {listHabit && listHabit.length > 0 ? (
          <>
            {listHabit.map((habit_item, index) => {
              return (
                <HabitCard
                  habit_item={habit_item}
                  handleClick={handleClick}
                  setListHabit={setListHabit}
                />
              );
            })}
          </>
        ) : (
          <div class="d-flex justify-content-center text-center align-item-center mt-5">
            <div class="empty-state__content">
              <div class="empty-state__message">
                No records has been added yet.
              </div>
              <div class="empty-state__help">
                Add a new record by simpley clicking the button on top right
                side.
              </div>
            </div>
          </div>
        )}
        {totalPages >= pageNumber ? (
          <tr>
            <div className="text-center mt-3">
              <span
                ref={lastBookElementRef}
                className=" mt-3"
                onClick={loadMoreData}
                id="load-more-feed"
              >
                {isLoadingMore ? (
                  <CircularProgress color="success" size="1.5rem" />
                ) : (
                  "Load More"
                )}
              </span>
            </div>
          </tr>
        ) : (
          ""
        )}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openNew}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          PaperProps={{
            elevation: 0,
            sx: {
              filter: "drop-shadow(0 5px 10px rgba(30,32,37,.12))",
              mt: 1.5,
            },
          }}
        >
          <div className="edit_icon">
            <MenuItem onClick={handleClickOpenEdit}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <span className="habbit_text">Edit </span>
            </MenuItem>
            <MenuItem onClick={handleDeletePopup}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <span className="habbit_text">Delete</span>
            </MenuItem>
          </div>
        </Menu>
        <CustomModal
          open={open}
          setOpen={setOpen}
          title="New Habit"
          page_data={
            <AddHabbit
              setOpen={setOpen}
              setListHabit={setListHabit}
              setTotalCount={setTotalCount}
              listHabit={listHabit}
            />
          }
        />
        <CustomModal
          open={edit}
          setOpen={setEdit}
          title="Edit Habit"
          page_data={
            <EditHabbit
              setOpen={setEdit}
              userId={userId}
              setListHabit={setListHabit}
            />
          }
        />
        <DeleteConfirmation
          confirm={confirm}
          isLoading={delLoading}
          setConfirm={setConfirm}
          deleteHabitList={deleteHabitList}
        />
        <BuyHabbit
          confirm={confirm1}
          isLoading={loading}
          setConfirm={setConfirm1}
        />
      </div>
    </div>
  );
};

export default Habbit;
