import { invokeApi } from "../invokeApi";

//customer init token
export const customerInitToken_Api = async () => {
  const requestObj = {
    path: `api/customer/init_with_token`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

//Stripe intent.
export const paymentByCust_Api = async (data) => {
  const requestObj = {
    path: `api/payment/payment_by_customer`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

//Stripe recurring.
export const recurringPayment_Api = async (data) => {
  const requestObj = {
    path: `api/payment/recurring_payment`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

//Stripe customer payment.
export const changeOneTimePayment_Api = async (data) => {
  const requestObj = {
    path: `api/payment/chnage_one_time_payment`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

//Stripe customer payment incomplete.
export const confirmIncompleteSub_api = async (data) => {
  const requestObj = {
    path: `api/payment/confirm_subscription`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
