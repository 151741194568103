import React from "react";
import PhoneInput from "react-phone-input-2";

export const CustomPhoneInput = ({
  setInputsState,
  inputState,
  phoneNumber,
  inputClass,
  dropdownClass,
  required,
}) => {
  const handleChangePhone = (phone) => {
    setInputsState({ ...inputState, contact_number: phone });
  };

  return (
    <>
      <PhoneInput
        country={"GB"}
        inputClass={inputClass}
        dropdownClass={dropdownClass}
        value={phoneNumber}
        enableSearch
        label="Phone Number"
        variant="outlined"
        id="outlined-basic"
        disableSearchIcon
        countryCodeEditable={false}
        onChange={handleChangePhone}
        buttonStyle={{
          border: "none",
          backgroundColor: "transparent",
        }}
        inputProps={{
          name: "phone",
          required: required ? true : false,
          autoComplete: "off",
        }}
      />
    </>
  );
};
