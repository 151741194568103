import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { DashboardLayout, LoginLayout } from "../layouts";
import {
  Billing,
  CheckOut,
  Habbit,
  PaymentSucessfull,
  Settings,
} from "../pages";
import Login from "../pages/Login/Login";
import Signup from "../pages/Login/Signup";
import ForgotPassword from "../pages/Login/ForgotPassword";

const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/habits"> </Navigate>;
  } else {
    return <Navigate to="/login"> </Navigate>;
  }
};

export default function Routers() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route path="/habits" element={<Habbit />} />
        <Route path="/billings" element={<Billing />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/billings/check-out/:id" element={<CheckOut />} />
        <Route
          path="/billings/check-out/payment-sucessfull"
          element={<PaymentSucessfull />}
        />
      </Route>

      <Route element={<LoginLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Route>
    </Routes>
  );
}
