import React, { createContext, useContext, useEffect, useState } from "react";

const AdminContext = createContext();

export const useAdminContext = () => useContext(AdminContext);
export function ContextAdmin({ children }) {
  const [userinfo, setUserInfo] = useState(null);
  const [planInfo, setPlanInfo] = useState(null);
  const [isToken, setIsToken] = useState(null);
  const [isBilling, setIsBilling] = useState(null);
  const [adminsettingList, setAdminSettingList] = useState({});
  const [isLogOut, setIsLogOut] = useState(false);

  const handleSettingList = (value) => {
    setAdminSettingList(value);
  };

  const handleStripe = (value) => {
    setIsToken(value);
  };

  const handleId = (value) => {
    setIsBilling(value);
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUserInfo(JSON.parse(user));
    }
  }, [localStorage.getItem("user")]);

  useEffect(() => {
    const currentPlan = localStorage.getItem("current_plan");
    if (!!currentPlan) {
      setPlanInfo(JSON.parse(currentPlan));
    }
  }, [localStorage.getItem("current_plan")]);

  const collection = {
    setUserInfo,
    userinfo,
    setIsToken,
    isToken,
    isBilling,
    handleId,
    handleSettingList,
    adminsettingList,
    handleStripe,
    setPlanInfo,
    planInfo,
    isLogOut,
    setIsLogOut,
  };

  return (
    <AdminContext.Provider value={collection}>{children}</AdminContext.Provider>
  );
}
