import React from "react";
import {
  CategoryIcon,
  DocumentIcon,
  LogoutIcon,
  SettingIcon,
  SquareIcon,
} from "../../assets";
import { logout_Api } from "../../DAL/Login/Login";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAdminContext } from "../../context/Context";

export const SideBarConfig = () => {
  const navigate = useNavigate();
  const { setPlanInfo, setIsLogOut } = useAdminContext();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async (e) => {
    e.preventDefault();
    setIsLogOut(true);
    const result = await logout_Api();
    if (result.code === 200) {
      localStorage.clear();
      navigate("/login");
      setPlanInfo(null);
      setIsLogOut(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLogOut(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  let sidebarMenus = [];

  // sidebarMenus.push({
  //   title: "Dashboard",
  //   path: "/dashboard",
  //   icon: <CategoryIcon />,
  // });
  sidebarMenus.push({
    title: "All Habits",
    path: "/habits",
    icon: <SquareIcon />,
  });
  sidebarMenus.push({
    title: "Billings",
    path: "/billings",
    icon: <DocumentIcon />,
  });
  sidebarMenus.push({
    title: "Settings",
    path: "/settings",
    icon: <SettingIcon />,
  });
  sidebarMenus.push({
    title: "Log Out",
    icon: <LogoutIcon />,
    on_click: handleLogout,
  });
  // let invoice_child_array = [];

  // invoice_child_array.push({
  //   title: "Invoice",
  //   path: "/invoice",
  // });
  // invoice_child_array.push({
  //   title: "Payments",
  //   path: "/payments",
  // });
  // invoice_child_array.push({
  //   title: "Taxes",
  //   path: "/taxes",
  // });
  // invoice_child_array.push({
  //   title: "Report",
  //   child_options: [
  //     {
  //       title: "Payment Summary",
  //       path: "/payment-summary",
  //     },
  //     {
  //       title: "Sale Report",
  //       path: "/sale-report",
  //     },
  //     {
  //       title: "Expenses Report",
  //       path: "/expenses-report",
  //     },
  //   ],
  // });
  // invoice_child_array.push({
  //   title: "Transaction",
  //   child_options: [
  //     {
  //       title: "Transaction List",
  //       path: "/transaction-list",
  //     },
  //   ],
  // });
  // invoice_child_array.push({
  //   title: "Users",
  //   path: "/users",
  // });
  // sidebarMenus.push({
  //   title: "Invoices Management",
  //   icon: <DescriptionOutlinedIcon />,
  //   child_options: invoice_child_array,
  // });

  return sidebarMenus;
};
