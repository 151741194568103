import { invokeApi } from "../invokeApi";

//Add new habit.
export const addHabit_Api = async (data) => {
  const requestObj = {
    path: `api/habit_tracker/add_habit`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

//Show list habit.
export const listHabit_Api = async (path) => {
  const requestObj = {
    path: path,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

//Update new habit.
export const editHabit_Api = async (data, id) => {
  const requestObj = {
    path: `api/habit_tracker/update_habit/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

//Delete habit.
export const deleteHabit_Api = async (id) => {
  const requestObj = {
    path: `api/habit_tracker/delete_habit/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

//Status habit.
export const detailsHabit_Api = async (id) => {
  const requestObj = {
    path: `api/habit_tracker/detail_habit/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

//Status change habit.
export const changeHabitStatus_Api = async (data) => {
  const requestObj = {
    path: `api/habit_tracker/change_status`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const delete_completion_object_api = async (
  habit_id,
  completions_id
) => {
  const requestObj = {
    path: `api/habit_tracker/delete_completion_object?habit_id=${habit_id}&completions_id=${completions_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
