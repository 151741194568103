import React, { useState } from "react";
import { DialogActions, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { addHabit_Api } from "../../DAL/Habit/Habit";
import momentTimezone from "moment-timezone";
import { useAdminContext } from "../../context/Context";

const AddHabbit = ({ setOpen, listHabit, setListHabit, setTotalCount }) => {
  const [isloading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { userinfo } = useAdminContext();
  const [inputs, setInputs] = useState({
    name: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const start_date = momentTimezone.tz(userinfo.timezone).format("YYYY-MM-DD");

  const handleSubmitHabit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const postData = {
      name: inputs.name,
    };
    if (userinfo.timezone) {
      postData.start_date = start_date;
    }
    const result = await addHabit_Api(postData);
    if (result.code === 200) {
      const updatedListHabit = [result.habit.habit, ...listHabit];
      setListHabit(updatedListHabit);
      setTotalCount((prev) => ({
        ...prev,
        count: prev.count + 1,
      }));
      setOpen(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmitHabit}>
      <div className="row">
        <div className="col-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Name"
            name="name"
            variant="outlined"
            value={inputs.name}
            onChange={(e) => handleChange(e)}
            fullWidth
            required
            placeholder="Name *"
          />
        </div>
        <div className="mt-4">
          <DialogActions sx={{ padding: "0px" }}>
            <button
              className="outline_btn_popover"
              onClick={(e) => {
                handleClose();
                e.preventDefault();
              }}
            >
              Cancel
            </button>
            <LoadingButton
              sx={{
                background: "#13c57c",
                border: "none",
                color: "#fff",
                padding: "6px 20px",
                borderRadius: "6px",
                fontSize: "14px !important",
                marginBottom: "8px",
                boxShadow: 0,
                textTransform: "none",
              }}
              size="large"
              type="submit"
              variant="contained"
              loading={isloading}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </div>
      </div>
    </form>
  );
};

export default AddHabbit;
