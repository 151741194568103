import { CircularProgress, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { listHabitPlan_Api } from "../../DAL/Plan/Plan";
import { useSnackbar } from "notistack";
import { useAdminContext } from "../../context/Context";

const Billing = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isBilling } = useAdminContext();
  const [loading, setLoading] = useState(true);
  const [listPlan, setListPlan] = useState([]);

  const handleListPlan = async () => {
    setLoading(true);
    const result = await listHabitPlan_Api();
    if (result.code === 200) {
      setListPlan(result.plan_list);
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  const handlePlanClick = (value) => {
    navigate(`/billings/check-out/${value._id}`);
  };

  useEffect(() => {
    handleListPlan();
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center alignCenter">
        <CircularProgress color="success" />
      </div>
    );
  }

  return (
    <>
      <section className="pricing py-3 ">
        <div className="container">
          <div className="row">
            {listPlan.map((item, index) => {
              return (
                <div className="col-lg-6" key={index}>
                  <div
                    className={
                      isBilling === item._id
                        ? "card subscribed_plan mb-5 mb-lg-0"
                        : isBilling === undefined && item.is_plan_free
                        ? "card subscribed_plan mb-5 mb-lg-0"
                        : "card mb-5 mb-lg-0"
                    }
                  >
                    <div
                      className={
                        item.type === "onetime"
                          ? "card-header pro"
                          : item.type === "recurring"
                          ? "card-header price_plan"
                          : "card-header"
                      }
                    >
                      {item.type === "onetime" ? (
                        <span className="badge">Popular</span>
                      ) : (
                        ""
                      )}
                      <h5 className="card-title text-uppercase text-center">
                        {item.name}
                      </h5>
                      <h6 className="card-price text-center">
                        ${item.total_amount}
                        <span className="period">
                          {item.type === "onetime"
                            ? "/One Time Purchase"
                            : item.type === "recurring"
                            ? "/Yearly Plan"
                            : "/Free Plan"}
                        </span>
                      </h6>
                    </div>
                    <div className="card-body mt-2 billing-card">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.description,
                        }}
                      ></div>
                      <div className="mb-2 mt-4">
                        {isBilling === item._id ? (
                          <Tooltip
                            placement="top"
                            title="You are already subscribed to this plan"
                          >
                            <button className="btn btn-primary w-100 text-uppercase btn_disable">
                              Subscribed
                            </button>
                          </Tooltip>
                        ) : item.is_plan_free === true ? (
                          <div className="mb-2 mt-4 p-3"></div>
                        ) : (
                          <button
                            onClick={() => {
                              handlePlanClick(item);
                            }}
                            className="btn btn-primary w-100 text-uppercase"
                          >
                            {item.type === "onetime"
                              ? "Lifetime Checkout"
                              : item.type === "recurring"
                              ? " Yearly Checkout"
                              : ""}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Billing;
