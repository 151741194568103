import { Checkbox, FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import ContributionChart from "../../components/ContributionChart";
import {
  changeHabitStatus_Api,
  delete_completion_object_api,
} from "../../DAL/Habit/Habit";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useAdminContext } from "../../context/Context";
import momentTimezone from "moment-timezone";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export default function HabitCard({ habit_item, handleClick, setListHabit }) {
  const { enqueueSnackbar } = useSnackbar();
  const [detailHabbit, setDetailHabbit] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const { userinfo } = useAdminContext();

  const handleClickHabit = (value) => {
    if (value) {
      setSelectedObject(value);
    }
  };

  const handle_update_status = (status) => {
    setDetailHabbit((old) =>
      old.map((item) => {
        if (item.date === selectedObject.date) {
          item.status = status;
        }
        return item;
      })
    );
  };

  const handleSubmit = async (date, habit_id) => {
    const postData = {
      habit_id: habit_id,
      date: date,
      status: true,
    };
    const result = await changeHabitStatus_Api(postData);
    if (result.code === 200) {
      handle_update_status(true);
      setListHabit((old) =>
        old.map((item) => {
          if (item._id === habit_id) {
            item = result?.habit?.habit;
          }
          return item;
        })
      );

      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDelete = async (date, habit_id) => {
    const result = await delete_completion_object_api(habit_id, date);
    if (result.code === 200) {
      handle_update_status(false);
      setListHabit((old) =>
        old.map((item) => {
          if (item._id === habit_id) {
            item = result.habit;
          }
          return item;
        })
      );

      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  function getDates(startDate, endDate, existingDates) {
    const matchingDates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const existingDatesArray = Array.from(existingDates);
      const dateString = currentDate.toISOString().slice(0, 10);
      const exists = existingDatesArray.includes(dateString);
      matchingDates.push({
        date: dateString,
        status: exists,
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return matchingDates;
  }

  const handlePopup = (e) => {
    let checked = e.target.checked;
    setSelectedObject((old) => ({ ...old, status: checked }));
    if (checked) {
      handleSubmit(selectedObject.date, habit_item._id);
    } else {
      handleDelete(selectedObject.date, habit_item._id);
    }
  };

  function formatDateArray(dateArray, timezone) {
    if (timezone) {
      const formattedDates = dateArray.map((item) => {
        const dateInTimeZone = momentTimezone
          .tz(item.date, timezone)
          .format("YYYY-MM-DD");
        return { ...item, date: dateInTimeZone };
      });
      return formattedDates;
    } else {
      return dateArray;
    }
  }

  useEffect(() => {
    if (habit_item) {
      const startDate = new Date(habit_item.start_date);
      const endDate = new Date();
      const existingDates = new Set(
        habit_item.completions?.map((completion) =>
          moment(completion.date).format("YYYY-MM-DD")
        )
      );
      const dateArray = getDates(startDate, endDate, existingDates);
      const formattedDate = formatDateArray(dateArray, userinfo?.timezone);
      setDetailHabbit(formattedDate);
    }
  }, [habit_item]);

  useEffect(() => {
    const startDate = new Date(habit_item.start_date);
    const endDate = new Date();
    const existingDates = new Set(
      habit_item.completions?.map((completion) =>
        moment(completion.date).format("YYYY-MM-DD")
      )
    );
    const dateArray = getDates(startDate, endDate, existingDates);
    const formattedDate = formatDateArray(dateArray, userinfo?.timezone);
    setSelectedObject(formattedDate[formattedDate.length - 1]);
  }, []);

  return (
    <div className="mt-4 card shadow_card">
      <div className="card-body w-100 color-b d-flex flex-wrap justify-align-content-xl-end align-items-center px-3">
        <div className="me-auto chart_check">
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(selectedObject?.status)}
                onChange={(e) => handlePopup(e)}
                icon={<CheckBoxIcon style={{ fill: "#808080d5" }} />}
                checkedIcon={<CheckBoxIcon />}
              />
            }
            label={habit_item?.name}
          />
        </div>
        <div className="row d-xl-none ">
          <div className="col-12 col-sm-6">
            <div className="streak_text d-flex align-items-center  me-5 mt-2 mt-md-0">
              <span className="me-2">{habit_item?.current_streak}</span>
              <p>Day Streak</p>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="streak_text d-flex align-items-center me-5  mt-md-0">
              <span className="me-2">{habit_item?.longest_streak}</span>
              <p>Longest Streak</p>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="streak_text d-flex align-items-center me-4 me-lg-5">
              <span className="me-2">{habit_item?.total_completions}</span>
              <p>Days Completed</p>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="streak_text d-flex align-items-center me-lg-5 mt-md-0 flex-wrap ">
              <span className="me-2 ">
                {habit_item?.consistency?.toFixed(2)}%
              </span>
              <p>Consistency</p>
            </div>
          </div>
        </div>
        <div className="streak_text d-none d-xl-flex align-items-center me-5 mt-2 mt-md-0">
          <span className="me-2">{habit_item?.current_streak}</span>
          <p>Day Streak</p>
        </div>
        <div className="streak_text d-none d-xl-flex align-items-center ps-3 me-5 mt-2 mt-md-0">
          <span className="me-2">{habit_item?.longest_streak}</span>
          <p>Longest Streak</p>
        </div>
        <div className="streak_text d-none d-xl-flex align-items-center me-4 me-lg-5">
          <span className="me-2">{habit_item?.total_completions}</span>
          <p>Days Completed</p>
        </div>
        <div className="streak_text d-none d-xl-flex align-items-center ps-3 me-lg-5 mt-md-0">
          <span className="me-2">{habit_item?.consistency?.toFixed(2)}%</span>
          <p>Consistency</p>
        </div>
      </div>
      <div
        className="text-end pointer acc_icon"
        onClick={(e) => {
          handleClick(e, habit_item);
        }}
      >
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </div>
      <ContributionChart
        habitList={habit_item}
        setListHabit={setListHabit}
        detailHabbit={detailHabbit}
        handleClickHabit={handleClickHabit}
      />
    </div>
  );
}
