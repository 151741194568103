import { invokeApi } from "../invokeApi";
import { baseApikey } from "../../config/config";

//login user to get the token for further requests.
export const login = async (data) => {
  const requestObj = {
    path: `api/customer/login`,
    method: "POST",
    headers: {
      "x-api-key": baseApikey,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

//signup to create a new customer.
export const signUp_Api = async (data) => {
  const requestObj = {
    path: `api/customer/signup_customer`,
    method: "POST",
    headers: {
      "x-api-key": baseApikey,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

//Logout User
export const logout_Api = async () => {
  const requestObj = {
    path: `api/app_api/logout`,
    method: "GET",
    headers: {
      // "x-api-key": baseApikey,
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};

//email verifaction  for the user
export const emailVerify_Api = async (data) => {
  const requestObj = {
    path: `api/app_api/email_verification`,
    method: "POST",
    headers: {
      "x-api-key": baseApikey,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

//code/pin verifaction  for the user
export const codeVerify_Api = async (data) => {
  const requestObj = {
    path: `api/app_api/code_verification`,
    method: "POST",
    headers: {
      "x-api-key": baseApikey,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

//Change user password.
export const pass_ConfirmPass_Api = async (data) => {
  const requestObj = {
    path: `api/app_api/reset_password`,
    method: "POST",
    headers: {
      "x-api-key": baseApikey,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

//Update user profile information.
export const userProfileUpdate_Api = async (data) => {
  const requestObj = {
    path: `api/profile/update_profile`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
