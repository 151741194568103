import { invokeApi } from "../invokeApi";

//Plan List.
export const listHabitPlan_Api = async () => {
  const requestObj = {
    path: `api/plans/list_plan`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};
