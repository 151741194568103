import React from "react";
import { Dialog, DialogTitle } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

function BuyHabbit({ confirm, isLoading, setConfirm }) {
  const navigate = useNavigate();

  const handleCloseDialog = () => {
    setConfirm(false);
  };

  const BuyHabit = () => {
    navigate("/billings");
  };
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Dialog
        open={confirm}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
            width: isMdScreen ? "500px" : "100%",
          },
        }}
      >
        <DialogTitle>
          <div className="text-center p-4">
            <div className="mt-3">
              <h4>Please upgrade your plan</h4>
              <p className="text-muted mx-4 mb-0">
                To add more habits you have to buy subscription.
              </p>
            </div>
            <div className="d-flex justify-content-center gap-2 mt-4">
              <button
                className="outline_btn_popover_2"
                onClick={handleCloseDialog}
              >
                Close
              </button>
              <LoadingButton
                sx={{
                  background: "#13c57c",
                  border: "none",
                  color: "#fff",
                  padding: "7px 20px",
                  borderRadius: "6px",
                  fontSize: "14px !important",
                  marginBottom: "15px",
                  boxShadow: 0,
                  textTransform: "none",
                }}
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
                onClick={BuyHabit}
              >
                Buy
              </LoadingButton>
            </div>
          </div>
        </DialogTitle>
      </Dialog>
    </>
  );
}

export default BuyHabbit;
