import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";

const PaymentSucessfull = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/habits`);
  };
  return (
    <div className="container">
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-12 text-center">
          <div className="sucess_icon">
            <CheckIcon />
            <h6 className="fw_600 mt-4">Your payment was succesful</h6>
            <p>Now you have a Premium subscription.</p>
            <button className="button_popover" onClick={handleClick}>
              Go to Habits
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSucessfull;
