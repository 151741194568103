import React, { useEffect, useState } from "react";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useAdminContext } from "../../context/Context";

const CheckOut = () => {
  const { isToken } = useAdminContext();
  const stripeKey = isToken?.stripe_settings?.sandBox_publish_key;

  return (
    <Elements stripe={loadStripe(stripeKey)}>
      <CheckoutForm />
    </Elements>
  );
};

export default CheckOut;
