import React from "react";
import moment from "moment";
import CalendarHeatmap from "react-calendar-heatmap";
import { Tooltip } from "react-tooltip";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import momentTimezone from "moment-timezone";
import { useAdminContext } from "../context/Context";

const ContributionChart = ({ detailHabbit, handleClickHabit }) => {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const { userinfo } = useAdminContext();

  let startDate = "";
  let endDate = "";
  if (isMdScreen) {
    startDate = moment().startOf("year");
    endDate = moment().endOf("year");
  } else {
    startDate = moment().startOf("month");
    endDate = moment().endOf("month");
  }

  function isSameDayInTimeZone(valueDate) {
    let formet_data = moment(valueDate).format("YYYY-MM-DD");
    if (userinfo.timezone) {
      const valueDateInTimeZone = momentTimezone.tz(
        formet_data,
        userinfo.timezone
      );
      const currentDateInTimeZone = momentTimezone().tz(userinfo.timezone);
      return valueDateInTimeZone.isSame(currentDateInTimeZone, "day");
    } else {
      return valueDate.isSame(moment(), "day");
    }
  }

  return (
    <>
      <CalendarHeatmap
        startDate={startDate}
        endDate={endDate}
        onClick={(value) => handleClickHabit(value)}
        values={detailHabbit}
        classForValue={(value) => {
          if (!!value) {
            const valueDate = moment(value.date);
            if (value.status === false && isSameDayInTimeZone(valueDate)) {
              return "color-github-1";
            } else if (
              value.status === true &&
              isSameDayInTimeZone(valueDate)
            ) {
              return "color-github-3";
            } else if (value.status === false) {
              return "color-github-0";
            } else if (value.status === true) {
              return "color-github-3";
            } else {
              return "color-empty";
            }
          } else {
            return "color-empty";
          }
        }}
        titleForValue={(value) => {
          if (value && value.date) {
            let format_date = moment(value.date).format("DD-MM-YYYY");
            if (value.status === false) {
              return `${format_date} You have not completed this Habit`;
            } else {
              return `${format_date} You have Completed this Habit`;
            }
          }
        }}
        showWeekdayLabels={true}
      />
      <Tooltip />
    </>
  );
};

export default ContributionChart;
